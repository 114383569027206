import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonButtons, IonButton, IonCard } from '@ionic/react';
import React from 'react';
import { deleteFile } from '../services/image.service';

interface IProps {
    imageUrl: string;
    onCloseHandler: Function;
    onDeleteHandler: Function;
}

const ImageViewer: React.FC<IProps> = (props) => {
    async function deleteImage(url: string) {
        try {
            await deleteFile(url);
            props.onDeleteHandler(url);
            props.onCloseHandler(false);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="end">
                        <IonButton onClick={e => props.onCloseHandler(false)}>
                            Close
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Image Viewer</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonItem lines="none">
                    <IonCard>
                        <img src={props.imageUrl} />
                    </IonCard>
                </IonItem>
                <IonButton expand="block" color="danger" onClick={e => deleteImage(props.imageUrl)}>Delete</IonButton>
            </IonContent>
        </IonPage>
    );
};

export default ImageViewer;
