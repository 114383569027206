import React, { useEffect, useRef } from "react";
import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonItem, IonLabel, IonList, IonPage, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar } from "@ionic/react";
import { LeadStages, LeadStagesLabels } from "./CreateLeadOrder";
import "./OrderFilter.css";

type Props = {
  filters: Record<string, any>;
  updateFilters: (fitlers: Record<string, any>) => void;
  onClose: () => void;
}

const OrderFilter: React.FC<Props> = (props) => {
  const { filters, updateFilters, onClose } = props;

  const leadStage = useRef<LeadStages[]>([]);
  useEffect(() => {
    if (!!filters && filters.hasOwnProperty('stage')) {
      leadStage.current = filters.stage as LeadStages[];
    }
  }, [filters])

  const submitFilters = () => {
    const filters: Record<string, any> = {};
    if (!!leadStage.current) {
      filters.stage = leadStage.current
    }
    updateFilters(filters);
    onClose();
  }

  const handleStageChange = (newStageIds: LeadStages[]) => {
    leadStage.current = [...newStageIds];
  }

  const handleReset = () => {
    updateFilters({});
    onClose();
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Filter</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">Stages</IonLabel>
            <IonSelect id="stages" name="stages" compareWith={compareWith} multiple={true} value={leadStage.current} placeholder="Select Stages" onIonChange={(e) => handleStageChange(e.detail.value)}>
              {Object.entries(LeadStagesLabels).map(([key, value]) => {
                return <IonSelectOption key={key} value={key}>{value}</IonSelectOption>
              })}
            </IonSelect>
          </IonItem>
        </IonList>

      </IonContent>
      <IonToolbar>
        <IonGrid>
          <IonRow >
            <IonCol>
              <IonButton expand="block" fill="outline" color="danger" onClick={handleReset}>Reset</IonButton>
            </IonCol>
            <IonCol>
              <IonButton expand="block" color="success" onClick={submitFilters}>Submit</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonToolbar>
    </IonPage>
  )
}

export default OrderFilter;


const compareWith = (o1: any, o2: any) => {
  if (Array.isArray(o2)) {
    return o2.indexOf(o1) !== -1;
  }
  return o1 === o2;
};