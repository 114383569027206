import React, { useEffect, useRef, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonCard, IonChip, IonCol, IonContent, IonDatetime, IonGrid, IonHeader, IonIcon, IonItem,
  IonLabel, IonModal, IonRow, IonText, IonTextarea, IonTitle, IonToast,
  IonToolbar
} from "@ionic/react";
import useNavigation from "../hooks/useNavigation";
import "./OrderCards.css";
import { makeCall } from "../hooks/common";
import { updateOrder } from "../services/order.service";
import { handleErrors } from "../services/common.service";
import moment from "moment";
import { call } from "ionicons/icons";
import { LeadStages, LeadStagesActionSheet, LeadStagesLabels } from "./CreateLeadOrder";

interface IProps {
  order: any;
  onOrderUpdate: () => void;
}
type DateType = "followUpDate" | "completionDate" | null;

const getDateDetails = (order: any) => {
  const { completionDate, followUpDate } = order;
  return {
    type: !!completionDate ? 'Completion Date' : (!!followUpDate ? 'Followup Date' : null),
    date: !!completionDate ? completionDate : (!!followUpDate ? followUpDate : "")
  }
}

const OrderCard: React.FC<IProps> = (props) => {
  const { order, onOrderUpdate } = props;

  const navigateTo = useNavigation();
  const [leadStage, setLeadStage] = useState<LeadStages>();
  const dateRef = useRef<{ type: string | null, date: any }>();

  const [showDateSelection, setShowDateSelection] = useState<DateType>(null);

  const [onSuccess, setOnSuccess] = useState("");
  const [onError, setOnError] = useState("");
  const [showStageSelection, setShowStageSelection] = useState<boolean>(false);

  const [priceComment, setPriceComment] = useState<string>(order?.priceComment ?? '');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!!order) {
      setLeadStage(order.stage);
      setDate(getDateDetails(order));
    }
  }, [order])


  useEffect(() => {
    if (!!leadStage?.length) {
      if (order.stage === leadStage) {
        return;
      }
      updateOrderStage();
    }
  }, [leadStage]); // eslint-disable-line

  const updateOrderStage = async () => {
    if (order?.id) {
      const payload = order;
      delete payload["servicer"];
      delete payload["crop"];
      setIsLoading(true);
      updateOrder({
        ...payload,
        stage: leadStage,
      })
        .then((response) => {
          if (!response.ok) {
            setOnError('Something went wrong');
          } else {
            order.stage = leadStage;
            setOnSuccess("Stage updated");
          }
        })
        .catch(handleErrors)
        .finally(finishLoading);
    }
  };

  const finishLoading = () => {
    setIsLoading(false);
    onOrderUpdate();
  }

  const closeDateSelection = () => setShowDateSelection(null);
  const openDateSelection = (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>, type: DateType) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDateSelection(type);
  }

  const updateOrderDetails = async (payload: any, removeKeys: string[] = []) => {
    if (!!order?.id) {
      const data = { ...order };

      [...removeKeys, "servicer", "crop"].forEach(key => {
        delete data[key];
      })

      updateOrder({
        ...data,
        ...payload
      })
        .then(() => setOnSuccess("Stage updated"))
        .catch(handleErrors)
        .catch(error => setOnError(error))
        .finally(finishLoading)
    }
  }

  const closeStageSelection = () => setShowStageSelection(false);
  const openStageSelection = (e: any) => {
    e.stopPropagation();
    setShowStageSelection(true);
  }

  const updateStage = (stageId: LeadStages) => {
    setLeadStage(stageId);
  }

  const handleDate = (e: any) => {
    if (!showDateSelection) {
      return;
    }
    const date = e.detail?.value ?? "";
    setDate({
      type: showDateSelection,
      date
    });

    const newStage = showDateSelection === "completionDate" ? "installing" : "later";
    const dateToDelete = showDateSelection === "completionDate" ? "followUpDate" : "completionDate";

    if(newStage === "later") {
      updateOrderDetails({ [showDateSelection]: date, stage: newStage }, [dateToDelete]);
      closeDateSelection();
    }
  }

  const handleInstalling = () => {
    if(!date?.date) {
      setOnError("Please select a valid date");
      return;
    }
    if(!priceComment) {
      setOnError("Please add a comment for the installing stage");
      return;
    }
    updateOrderDetails({ completionDate: date?.date, stage: LeadStages.Installing, priceComment }, ["followUpDate"]);
    closeDateSelection();
  }

  const setDate = (newDate: any) => {
    dateRef.current = newDate;
  }

  const { isNewLead } = order;

  const isInstalling = !!order?.completionDate;
  const isFollowUp = !!order?.followUpDate;
  const date = dateRef.current;

  return (
    <>
      <IonCard style={{ position: 'relative', }}>
        <IonRow className="card-header">
          <IonChip className="chip">
            {isNewLead ? "New User" : "Existing User"}
          </IonChip>
          <IonChip className="chip">
            {moment(order.created_at).format("DD/MM/YYYY")}
          </IonChip>
        </IonRow>
        <IonGrid onClick={() => navigateTo("/tabs/order", { order })} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <IonRow style={{ padding: "0px 20px" }}>
            <IonText style={{
              whiteSpace: 'pre-wrap',
              display: '-webkit-box',
              WebkitLineClamp: 5,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
            }}>
              {order?.detail ?? null}
              <br />
              {isInstalling && (order?.priceComment ?? null)}
            </IonText>
          </IonRow>

          <IonRow>
            <IonCol>
              {
                (!!date?.type && (isFollowUp || isInstalling)) && <IonItem lines="none">
                  <IonLabel>
                    <p style={labelStyle}>{date.type}</p>
                    <p style={valueStyle}>{moment(date.date).format("DD/MM/YYYY")}</p>
                  </IonLabel>
                </IonItem>
              }
            </IonCol>

            <IonCol>
              {order.cropId && (
                <IonItem lines="none">
                  <IonLabel>
                    <p style={labelStyle}>Crop:</p>
                    <p style={valueStyle}> {order?.crop?.name || order?.cropId}</p>
                  </IonLabel>
                </IonItem>
              )}
            </IonCol>
          </IonRow>

          <IonRow style={{ justifyContent: "space-between", alignItems: "center" }}>
            <IonButton
              className="action-button"
              fill="outline"
              onClick={(e) => makeCall(e, order.mobile)}
              disabled={isLoading}
              mode="ios"
            >
              <IonIcon icon={call} size="small" />
            </IonButton>
            <IonCol size="4">
              <IonButton
                fill={(isFollowUp || isInstalling) ? "outline" : "solid"}
                onClick={openStageSelection}
                className="action-button"
                disabled={isLoading}
                expand="block"
                mode="ios"
              >
                {!!leadStage ? LeadStagesLabels[leadStage] : 'Stage'}
              </IonButton>
            </IonCol>
            <IonCol style={{ margin: 0, padding: 0 }}>
              <IonRow style={{ justifyContent: "center" }}>
                <IonCol size="6">
                  <IonButton
                    fill={isInstalling ? "solid" : "outline"}
                    className="action-button"
                    disabled={isLoading}
                    onClick={(e) => openDateSelection(e, "completionDate")}
                    mode="ios"
                  >
                    Installing
                  </IonButton>
                </IonCol>
                <IonCol size="4">
                  <IonButton
                    fill={isFollowUp ? "solid" : "outline"}
                    className="action-button"
                    disabled={isLoading}
                    onClick={(e) => openDateSelection(e, "followUpDate")}
                    mode="ios"
                  >
                    Later
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCard>

      <IonToast
        isOpen={!!onSuccess}
        onDidDismiss={() => setOnSuccess("")}
        message={onSuccess}
        duration={2000}
        color="success"
      />

      <IonToast
        isOpen={!!onError}
        onDidDismiss={() => setOnError("")}
        message={onError}
        duration={2000}
        color="danger"
      />

      <IonModal isOpen={!!showDateSelection} onDidDismiss={closeDateSelection}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Select Date</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={closeDateSelection}>
                <IonIcon icon="close" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonItem>
            <IonLabel>
              Date
            </IonLabel>
            <IonDatetime
              mode="ios"
              id="lead-date-selector"
              name="lead-date-selector"
              displayFormat="DD/MM/YYYY"
              placeholder="Select Date"
              onIonChange={handleDate}
              value={moment(order?.completionDate).toISOString()}
            />
          </IonItem>

          {showDateSelection === "completionDate" && (
            <IonItem lines="none">
              <IonTextarea
                mode="ios"
                rows={8}
                className="text-area"
                placeholder="
                  Nero: 10 @ 5000,
                  Kairo: 2 @ 10000
                "
                value={priceComment ?? ""}
                onIonChange={e => setPriceComment(e.detail.value ?? '')}
              />
            </IonItem>
          )}
        </IonContent>
        {showDateSelection === "completionDate" && <IonButton mode="ios" onClick={handleInstalling}>Submit</IonButton> }
      </IonModal>

      <LeadStagesActionSheet
        selected={leadStage}
        isOpen={showStageSelection}
        onClose={closeStageSelection}
        onChange={updateStage}
      />
    </>
  );
};

export default OrderCard;


const labelStyle = { fontSize: 12, fontWeight: 500 };
const valueStyle = {
}