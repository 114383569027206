import { IonAlert, IonButton, IonChip, IonCol, IonDatetime, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonList, IonModal, IonRow, IonSelect, IonSelectOption, IonSkeletonText, IonToast } from '@ionic/react';
import { useFormik } from 'formik';
import { addCircle, closeCircle, eyeOff, eyeSharp } from 'ionicons/icons';
import React, { useEffect, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import countryCodes from '../country_code.json';
import useNavigation from '../hooks/useNavigation';
import { Regex, handleErrors, toInputLowercase } from '../services/common.service';
import { deleteRole, generateOTPNoSend, getRoles, getUser, getUserRoles, registerUser, saveRole, searchUser, updateUser } from '../services/user.service';
import './CreateUser.css';
import LoadingSkeleton from './LoadingSkeleton';
import SelectItem from './SelectItem';
import { generateUniqueId } from '../hooks/common';
import moment from 'moment';

type TUserOTPProps = {
    isOTPLoading: boolean;
    userOTP: string | undefined;
};

/**
 * @component to render user otp
 * @param isOTPLoading defines the loading status for OTP generation
 * @param userOTP stores the user OTP
 * */ 
const UserOTP: React.FC<TUserOTPProps> = ({ isOTPLoading, userOTP }) => {
    return (
        <>
            {
                isOTPLoading && <IonItem>
                    <IonCol>
                        <IonSkeletonText animated style={{ width: '40%' }} />
                        <IonSkeletonText animated style={{ width: '70%' }} />
                    </IonCol>
                </IonItem>

            }
            {
                (!!userOTP && !isOTPLoading) && <IonItem>
                    <IonGrid>
                        <IonLabel position="stacked">OTP</IonLabel>
                        <IonCol>
                            <IonLabel>{userOTP}</IonLabel>
                        </IonCol>
                    </IonGrid>
                </IonItem>
            }
        </>
    )
};

const CreateUser = () => {
    const [loading, setLoading] = useState(false);
    const navigateTo = useNavigation();
    const location = useLocation();
    const [user, setUser] = useState<any>();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [onSuccess, setOnSuccess] = useState('');
    const [onError, setOnError] = useState('');

    const [userOTP, setUserOTP] = useState<string>();
    const [isOTPLoading, setIsOTPLoading] = useState<boolean>(false);
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [passwordVisible, togglePasswordVisible] = useReducer(state => !state, false);

    const [roles, setRoles] = useState([] as any[]);
    useEffect(() => {
        let mounted = true;
        getRoles()
            .then(items => {
                if (mounted) {
                    setRoles(items);
                }
            })
        return () => { mounted = false };
    }, []);

    const [showSelectCountryCodeModal, setShowSelectCountryCodeModal] = useState(false);

    function setCountryCode(item: any) {
        formik.setFieldValue('countryCode', item.dial_code);
        setShowSelectCountryCodeModal(false);
    }

    const [showSelectRolesModal, setShowSelectRolesModal] = useState(false);

    async function addRole(item: any) {
        formik.values.roles.push(item);
        setShowSelectRolesModal(false);
        if (formik.values.edit) {
            const response = await saveRole(formik.values.farmUserId, item.id).then(handleErrors);
        }
    }

    async function removeRole(itemIndex: number) {
        const removedRole = formik.values.roles.splice(itemIndex, 1);
        formik.setFieldValue('roles', [...formik.values.roles]);
        if (formik.values.edit) {
            const response = await deleteRole(formik.values.farmUserId, removedRole[0].id).then(handleErrors);
        }
    }

    function setUserValues(currentUser: any) {
        if (currentUser) {
            formik.setValues({
                edit: true,
                name: currentUser.name,
                farmUserId: currentUser.farmUserId,
                countryCode: currentUser.countryCode,
                mobile: currentUser.mobile,
                email: currentUser.email,
                gender: currentUser.gender,
                lang: currentUser.lang,
                roles: currentUser.roles,
                password: '',
                receivablesDate: currentUser.receivablesDate,
                receivables: currentUser.receivables
            });
        }
        setUser(currentUser);
    }

    useEffect(() => {
        let mounted = true;
        setLoading(true);
        const userId: any = (location.state as any)?.userId ?? null;
        if (userId) {
            getUser(userId)
                .then(user => {
                    if (mounted) {
                        setUserValues(user);
                        setLoading(false);
                    }
                });
        } else {
            setLoading(false);
        }
        return () => { mounted = false };
    }, []);

    const formik = useFormik({
        initialValues: {
            edit: false,
            name: '',
            farmUserId: '',
            countryCode: '+91',
            mobile: '',
            email: '',
            gender: '',
            lang: '',
            password: '',
            roles: [{
                id: 'farmuser',
                name: 'farmuser'
            }],
            receivables: 0,
            receivablesDate: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().trim().required('Required'),
            farmUserId: Yup.string().when('edit', {
                is: (val: boolean) => !val,
                then: Yup.string().trim().required('Required').matches(Regex.id, 'Id can only contain lowercase chars and numbers'),
            }),
            mobile: Yup.string().trim().matches(Regex.mobile, 'Invalid mobile number').required('Required'),
            email: Yup.string().trim().email('Invalid email address').required('Required'),
            gender: Yup.string().trim().required('Required'),
            lang: Yup.string().trim().required('Required'),
        }),
        onSubmit: values => {
            setShowConfirmation(true);
        },
    });

    const checkPassword = (values: any) => {
        const dataToBeSaved = Object.assign({}, values);

        if(dataToBeSaved.password === ''){
            delete dataToBeSaved.password;
        } else {
            if(dataToBeSaved.password !== confirmPassword) {
                formik.setErrors({ password: "Password and confirm password should be same" });
                return;
            }
        };

        return dataToBeSaved;
    }

    const cleanEmptyData = (data: any) => {
        delete data.roles;
        if(!data.receivables || !data.receivablesDate) {
            delete data.receivablesDate;
        }
    }

    const saveUser = async (values: any) => {
        
        try {
            if (!user) {
                const roles = [...values.roles];
                const dataToBeSaved = checkPassword(values);
                cleanEmptyData(dataToBeSaved);
                const response = await registerUser(dataToBeSaved).then(handleErrors);
                for (const role of roles) {
                    const response = await saveRole(values.farmUserId, role.id).then(handleErrors);
                }
                setOnSuccess(response.message);
                navigateTo('/tabs/farm', {
                    farmUserId: response.farmUserId
                });
            } else {
                const dataToBeSaved = checkPassword(values);
                const updatedObj = Object.assign({}, user, dataToBeSaved);
                cleanEmptyData(updatedObj);
                const response = await updateUser(updatedObj).then(handleErrors);
                response.roles = await getUserRoles(dataToBeSaved.farmUserId).then(handleErrors);
                setOnSuccess('User details updated');
                setUserValues(response);
            }
        } catch (err: any) {
            setOnError(err);
        }
    };

    /**
     * @function to generate OTP manually
     * */ 
    const generateOTPManual = async () => {
        try {
            setIsOTPLoading(true);
            if(!!user) {
                const { username } = user;
                const response = await generateOTPNoSend(username).then(handleErrors);
                setUserOTP(response.otp);
                setOnSuccess('OTP generated');
            } else {
                setOnError('No User Found');
            }
        } catch(err: any) {
            setOnError(err);
        } finally {
            setIsOTPLoading(false);
        }
    };

    /**
     * @function to compare passwords of password & confirm password field
     */
    const updateConfirmPassword = (e: any) => {
        const text = e.detail.value;
        setConfirmPassword(text);
    }

    const arePasswordsEqual = confirmPassword?.length === 0 ? true : confirmPassword === formik.values.password;
    
    function generateId(){
        if(!formik.values.edit){
            generateUniqueId(formik.values.name, searchUser, (id: string) => formik.setFieldValue('farmUserId', id))
        }
    }

    return (
        <>
            {loading && <LoadingSkeleton />}
            {!loading &&
                <form onSubmit={formik.handleSubmit}>
                    <IonList>
                    <IonItem>
                            <IonLabel position="stacked">User Name</IonLabel>
                            <IonInput id="name" name="name" onBlur={() => generateId()} value={formik.values.name} placeholder="Name of the User" onIonChange={formik.handleChange}></IonInput>
                        </IonItem>
                        {formik.touched.name && formik.errors.name ? (<div className="errorMsg">{formik.errors.name}</div>) : null}

                        <IonItem>
                            <IonLabel position="stacked">User Id</IonLabel>
                            <IonInput id="farmUserId" name="farmUserId" disabled={formik.values.farmUserId!=null} value={formik.values.farmUserId} placeholder="Id of the User" onIonInput={toInputLowercase} onIonChange={formik.handleChange}></IonInput>
                        </IonItem>
                        {formik.touched.farmUserId && formik.errors.farmUserId ? (<div className="errorMsg">{formik.errors.farmUserId}</div>) : null}

                        <IonItem>
                            <IonLabel position="stacked">Country Code</IonLabel>
                            <IonInput id="countryCode" name="countryCode" readonly value={formik.values.countryCode} placeholder="Country Code" onClick={e => setShowSelectCountryCodeModal(true)}></IonInput>
                        </IonItem>

                        <IonItem>
                            <IonLabel position="stacked">Mobile Number</IonLabel>
                            <IonInput id="mobile" name="mobile" type="tel" inputmode="tel" value={formik.values.mobile} placeholder="Mobile No. of the User" onIonChange={formik.handleChange}></IonInput>
                        </IonItem>
                        {formik.touched.mobile && formik.errors.mobile ? (<div className="errorMsg">{formik.errors.mobile}</div>) : null}

                        <IonItem>
                            <IonLabel position="stacked">Email Address</IonLabel>
                            <IonInput id="email" name="email" type="email" inputmode="email" value={formik.values.email} placeholder="Email Id of the User" onIonChange={formik.handleChange}></IonInput>
                        </IonItem>
                        {formik.touched.email && formik.errors.email ? (<div className="errorMsg">{formik.errors.email}</div>) : null}

                        <IonItem>
                            <IonLabel position="stacked">Password</IonLabel>
                            <IonInput id="password" name="password" type="password" value={formik.values.password} placeholder="Password" onIonChange={formik.handleChange}></IonInput>
                        </IonItem>

                        <IonItem>
                            <IonLabel position="stacked">Confirm Password</IonLabel>
                            <div className='password-container'>
                                <IonInput id="confirm-password" name="confirm-password" type={passwordVisible ? "text" : "password"} placeholder="Confirm Password" onIonChange={updateConfirmPassword}>
                                </IonInput>
                                <IonIcon icon={passwordVisible ? eyeOff : eyeSharp} onClick={togglePasswordVisible} />
                            </div>
                        </IonItem>
                        {!arePasswordsEqual ? (<div className="errorMsg">Incorrect password</div>) : null}

                        <IonItem>
                            <IonLabel position="stacked">Gender</IonLabel>
                            <IonSelect id="gender" name="gender" value={formik.values.gender} placeholder="Select Gender" onIonChange={formik.handleChange}>
                                <IonSelectOption value="Female">Female</IonSelectOption>
                                <IonSelectOption value="Male">Male</IonSelectOption>
                                <IonSelectOption value="Other">Other</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                        {formik.touched.gender && formik.errors.gender ? (<div className="errorMsg">{formik.errors.gender}</div>) : null}

                        <IonItem>
                            <IonLabel position="stacked">Language</IonLabel>
                            <IonSelect id="lang" name="lang" value={formik.values.lang} placeholder="Select Language" onIonChange={formik.handleChange}>
                                <IonSelectOption value="en">English</IonSelectOption>
                                <IonSelectOption value="hi">Hindi</IonSelectOption>
                                <IonSelectOption value="ma">Marathi</IonSelectOption>
                                <IonSelectOption value="ka">Kannada</IonSelectOption>
                                <IonSelectOption value="gu">Gujarati</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                        {formik.touched.lang && formik.errors.lang ? (<div className="errorMsg">{formik.errors.lang}</div>) : null}

                        <IonItem>
                            <IonLabel position="stacked">Receivables</IonLabel>
                            <IonInput id="receivables" name="receivables" type="number" value={formik.values.receivables} placeholder="Amount" onIonChange={formik.handleChange}></IonInput>
                        </IonItem>

                        <IonItem>
                            <IonLabel position="stacked">Receivables Date</IonLabel>
                            <IonDatetime 
                                id="receivablesDate" 
                                name="receivablesDate" 
                                value={!!formik.values.receivablesDate ? moment(formik.values.receivablesDate).toLocaleString() : undefined} 
                                onIonChange={formik.handleChange} 
                            />
                        </IonItem>

                        <IonItem lines='none'>
                            <IonLabel>Roles</IonLabel>
                            <IonIcon icon={addCircle} slot="end" onClick={e => setShowSelectRolesModal(true)} />
                        </IonItem>
                        <IonItem>
                            <IonGrid>
                                <IonRow>
                                    {formik.values.roles.length > 0 && formik.values.roles.map((item, index) =>
                                        <IonCol key={index}>
                                            <IonChip outline={true} color="primary">
                                                <IonLabel>{item.name}</IonLabel>
                                                <IonIcon icon={closeCircle} onClick={e => removeRole(index)} />
                                            </IonChip>
                                        </IonCol>
                                    )}
                                    
                                    {formik.values.roles.length === 0 &&
                                        <IonCol><IonLabel class="secondary-text">No Roles assigned</IonLabel></IonCol>}
                                </IonRow>
                            </IonGrid>
                        </IonItem>
                        <UserOTP isOTPLoading={isOTPLoading} userOTP={userOTP} />
                    </IonList>

                    <IonButton color='medium' expand='block' type='button' onClick={generateOTPManual} > Generate OTP </IonButton>

                    <IonButton expand="block" type="submit">{(!user ? 'Create' : 'Update') + ' User'}</IonButton>
                </form>
            }

            <IonAlert
                isOpen={showConfirmation}
                onDidDismiss={() => setShowConfirmation(false)}
                header={'Confirm!'}
                message={'Please make sure that you have verified all the user details. Save user?'}
                buttons={[
                    {
                        text: 'No',
                        role: 'cancel',
                        cssClass: 'secondary'
                    },
                    {
                        text: 'Yes',
                        handler: () => {
                            saveUser(formik.values);
                        }
                    }
                ]}
            />

            {showSelectRolesModal && <IonModal isOpen={showSelectRolesModal} >
                <SelectItem
                    label='Select Role'
                    items={roles.filter(item => !formik.values.roles.map(item => item.id).includes(item.id))}
                    labelKey='id'
                    valueKey='id'
                    setItem={addRole}
                    selectedValue=''
                    onClose={setShowSelectRolesModal} />
            </IonModal>}

            {showSelectCountryCodeModal && <IonModal isOpen={showSelectCountryCodeModal} >
                <SelectItem
                    label='Select Country'
                    items={countryCodes}
                    labelKey='name'
                    valueKey='dial_code'
                    setItem={setCountryCode}
                    selectedValue=''
                    onClose={setShowSelectCountryCodeModal} />
            </IonModal>}

            <IonToast
                isOpen={!!onSuccess}
                onDidDismiss={() => setOnSuccess('')}
                message={onSuccess}
                duration={2000}
                color="success"
            />

            <IonToast
                isOpen={!!onError}
                onDidDismiss={() => setOnError('')}
                message={onError}
                duration={2000}
                color="danger"
            />
        </>
    );
};

export default CreateUser;