import {
    IonContent, IonHeader, IonPage,
    IonTitle, IonToolbar, IonButtons, IonMenuButton, IonFab, IonFabButton, IonIcon,
    IonRefresher, IonRefresherContent, IonInfiniteScroll, IonInfiniteScrollContent,
    IonItem,
    IonModal
} from '@ionic/react';
import { add, chevronDownCircleOutline, funnelOutline } from 'ionicons/icons';
import { memo, useEffect, useState } from 'react';
import Orders from '../components/Orders';
import { doReload } from '../hooks/common';
import useNavigation from '../hooks/useNavigation';
import { API_RESPONSE_LIMIT, getOrders } from '../services/order.service';
// debounce hook
import useDebounce from "../hooks/useDebounce";
import CustomSearchBox from '../components/CustomSearchBox/CustomSearchBox';
import { useRecoilValue } from 'recoil';
import { userProfileState } from '../services/state.service';
import { useLocation } from 'react-router';
import OrderFilter from '../components/OrderFilter';

const OrderList: React.FC = () => {
    const [searchText, setSearchText] = useState('');
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
    const userProfile: any = useRecoilValue(userProfileState);
    const navigateTo = useNavigation();
    const debounceSearchText = useDebounce(searchText, 1000);
    const [orders, setOrders] = useState([] as any[]);

    // filters
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [filter, setFilter] = useState<Record<string, string>>();

    const location = useLocation();

    useEffect(() => {
        const { pathname, state } = location;
        if (pathname === "/orders" && !!(state as any)?.refreshList) {
            fetchOrders(true);
        }
    }, [location]) // eslint-disable-line


    useEffect(() => {
        let mounted = true;
        fetchOrders(mounted);
        return () => { mounted = false };
    }, [debounceSearchText, filter]); // eslint-disable-line

    /**
     * API Calls
     * */

    const fetchOrders = (mounted: boolean, skip: number = 0) => {
        setDisableInfiniteScroll(false);
        getOrders(debounceSearchText, userProfile?.farmUserId, skip, filter)
            .then(items => {
                if (mounted) {
                    setOrders(items);
                }
            });
    }

    async function fetchNext($event: CustomEvent<void>) {
        const toSkip = orders.length;
        getOrders(debounceSearchText, userProfile?.farmUserId, toSkip, filter)
            .then(items => {
                if (items?.length > 0) {
                    setOrders([...orders, ...items]);
                    setDisableInfiniteScroll(items.length < API_RESPONSE_LIMIT);
                } else {
                    setDisableInfiniteScroll(true);
                }
                ($event.target as HTMLIonInfiniteScrollElement).complete();
            });
    }

    /**
     * State Handlers
     * */
    const handleFilter = (filter: Record<string, string>) => {
        setFilter(filter);
    }

    const openFilterModal = () => setShowFilterModal(true);
    const closeFilterModal = () => setShowFilterModal(false);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Orders</IonTitle>
                </IonToolbar>
                <IonItem lines='none'>
                    <CustomSearchBox setSearchText={setSearchText} />
                    <IonIcon
                        icon={funnelOutline}
                        slot="end"
                        onClick={openFilterModal}
                        size='small'
                    ></IonIcon>
                </IonItem>
            </IonHeader>
            <IonContent>
                <IonRefresher slot="fixed" onIonRefresh={doReload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>
                <Orders orders={orders} onOrderUpdate={() => fetchOrders(true, 0)} />
                <IonInfiniteScroll threshold="100px"
                    disabled={disableInfiniteScroll}
                    onIonInfinite={(e: CustomEvent<void>) => fetchNext(e)}>
                    <IonInfiniteScrollContent
                        loadingText="Loading more...">
                    </IonInfiniteScrollContent>
                </IonInfiniteScroll>
                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton>
                        <IonIcon icon={add} onClick={() => navigateTo('/tabs/order', { orderId: null })} />
                    </IonFabButton>
                </IonFab>
            </IonContent>

            <IonModal isOpen={showFilterModal} cssClass="my-custom-class">
                <OrderFilter
                    filters={filter ?? {}}
                    onClose={closeFilterModal}
                    updateFilters={handleFilter}
                />
            </IonModal>
        </IonPage>
    );
};

export default memo(OrderList);
