import React, { useState } from 'react';
import { IonAlert, IonCard, IonIcon, IonItem, IonLabel, IonToast } from '@ionic/react';
import { trashOutline } from 'ionicons/icons';
import { deleteVoucher } from '../services/payment.service';

interface IProps {
    voucher: any;
    removeVoucherHandler?: Function;
}

const VoucherCard: React.FC<IProps> = (props) => {

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedVoucher, setSelectedVoucher] = useState();
    const [onSuccess, setOnSuccess] = useState('');
    const [onError, setOnError] = useState('');

    const removeVoucher = async (voucher: any) => {
        try {
            if (props.removeVoucherHandler && voucher) {
                const response = await deleteVoucher(voucher.id);
                setOnSuccess('Voucher deleted successfully');
                props.removeVoucherHandler(voucher);
            }
        } catch (err: any) {
            setOnError(err);
        }
    };

    return (
        <>
            <IonCard>
                <IonItem>
                    <IonLabel>{props.voucher.recharge?.name || 'Initial'}</IonLabel>
                    {props.removeVoucherHandler && props.voucher.status === 'INACTIVE' && <IonIcon icon={trashOutline} onClick={e => { setSelectedVoucher(props.voucher); setShowConfirmation(true) }}></IonIcon>}
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>User Id: {props.voucher.userId}</h2>
                    </IonLabel>
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Plot Id: {props.voucher.plotId}</h2>
                    </IonLabel>
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Status: {props.voucher.status}</h2>
                    </IonLabel>
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Validity: {props.voucher.startDate ? new Intl.DateTimeFormat('en-GB', {
                            month: 'short',
                            day: '2-digit',
                            year: 'numeric',
                        }).format(new Date(props.voucher.startDate)) : '-'} {' - '}
                            {props.voucher.endDate ? new Intl.DateTimeFormat('en-GB', {
                                month: 'short',
                                day: '2-digit',
                                year: 'numeric',
                            }).format(new Date(props.voucher.endDate)) : '-'}</h2>
                    </IonLabel>
                </IonItem>
                <IonItem lines='none'>
                    <IonLabel>
                        <h2>Created on: {props.voucher.createdAt ? new Intl.DateTimeFormat('en-GB', {
                            month: 'short',
                            day: '2-digit',
                            year: 'numeric',
                        }).format(new Date(props.voucher.createdAt)) : '-'}</h2>
                    </IonLabel>
                </IonItem>
            </IonCard>

            <IonAlert
                isOpen={showConfirmation}
                onDidDismiss={() => setShowConfirmation(false)}
                header={'Confirm!'}
                message={'Are you sure you want to delete this voucher?'}
                buttons={[
                    {
                        text: 'No',
                        role: 'cancel',
                        cssClass: 'secondary'
                    },
                    {
                        text: 'Yes',
                        handler: () => {
                            removeVoucher(selectedVoucher);
                        }
                    }
                ]}
            />

            <IonToast
                isOpen={!!onSuccess}
                onDidDismiss={() => setOnSuccess('')}
                message={onSuccess}
                duration={2000}
                color="success"
            />

            <IonToast
                isOpen={!!onError}
                onDidDismiss={() => setOnError('')}
                message={onError}
                duration={2000}
                color="danger"
            />
        </>
    );
};

export default VoucherCard;
