import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonRefresher,
  IonRefresherContent,
  IonLabel,
  IonChip,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonList,
  IonItem,
  IonBadge,
} from "@ionic/react";
import {
  add,
  chevronDownCircleOutline,
  qrCodeOutline,
  skullOutline,
  constructOutline,
  readerOutline,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import Leads from "../components/Leads";
import { doReload } from "../hooks/common";
import useNavigation from "../hooks/useNavigation";
import {
  getOpenLeads,
  getUnattendedLeads,
  getUpcomingFollowups,
} from "../services/lead.service";
import { getUpcomingTasks } from "../services/maintenance.service";
import { getTickets } from "../services/zammad.service";
import Maintenances from "../components/Maintenances";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  userProfileState,
  zammadProfileState,
  openTicketsState
} from "../services/state.service";
import { useLocation } from "react-router";
import { getInactivePlots } from "../services/plot.service";

const Home: React.FC = () => {
  const navigateTo = useNavigation();
  const location = useLocation();
  const userProfile: any = useRecoilValue(userProfileState);
  const zammadProfile: any = useRecoilValue(zammadProfileState);

  const [openLeads, setOpenLeads] = useState(0);
  const [openTickets, setOpenTickets] = useRecoilState<any>(openTicketsState);
  const [faultyPlots, setFaultyPlots] = useState(0);

  useEffect(() => {
    let mounted = true;
    getOpenLeads(userProfile.username).then((item) => {
      if (mounted) {
        setOpenLeads(item?.count);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);


  useEffect(() => {
    if (!!userProfile?.regionIds?.length) {
      let mounted = true;
      getInactivePlots(6, userProfile).then((item) => {
        if (mounted) {
          setFaultyPlots(item?.length);
        }
      });
      return () => {
        mounted = false;
      };
    }
  }, []);

  const [unattendedLeads, setUnattendedLeads] = useState(0);
  useEffect(() => {
    let mounted = true;
    getUnattendedLeads(userProfile.username).then((item) => {
      if (mounted) {
        setUnattendedLeads(item.count);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);


  useEffect(() => {
    let mounted = true;
    if (zammadProfile?.id && location.pathname === "/tabs/home") {
      getTickets(zammadProfile?.id, 1).then((res: any) => {
        if (mounted) {
          const ticketsWithOpenState: any = [];
          for (const ticketId of res?.tickets) {
            const ticketData = res?.assets?.Ticket[ticketId];
            if ([1, 2, 3].includes(ticketData.state_id)) {
              ticketsWithOpenState.push(ticketData);
            }
          }
          setOpenTickets(ticketsWithOpenState)
        }
      });
    }

    return () => {
      mounted = false;
    };
  }, [zammadProfile, location]);

  const [followups, setFollowups] = useState([] as any[]);
  useEffect(() => {
    let mounted = true;
    getUpcomingFollowups(userProfile.username).then((items) => {
      if (mounted) {
        setFollowups(items);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  const [tasks, setTasks] = useState([] as any[]);
  useEffect(() => {
    let mounted = true;
    getUpcomingTasks(userProfile.username).then((items) => {
      if (mounted) {
        setTasks(items);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Home</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doReload}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText="Pull to refresh"
            refreshingSpinner="circles"
            refreshingText="Refreshing..."
          ></IonRefresherContent>
        </IonRefresher>
        <IonGrid>
          <IonRow>
            <IonCol className="ion-align-self-center">
              <IonChip>
                <IonLabel>Quick Links</IonLabel>
              </IonChip>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid>
          <IonRow>
            <IonCol className="ion-align-self-center">
              <IonButton
                size="small"
                onClick={() => navigateTo("/tabs/lead", { leadId: null })}
              >
                <IonIcon slot="start" icon={add} />
                Lead
              </IonButton>
              <IonButton
                size="small"
                onClick={() =>
                  navigateTo("/tabs/maintenance", { maintenanceId: null })
                }
              >
                <IonIcon slot="start" icon={add} />
                Maintenance
              </IonButton>
              <IonButton
                size="small"
                onClick={() => navigateTo("/tickets-overview", { reload: true })}
              >
                <IonIcon slot="start" icon={readerOutline} />
                Tickets Overview
              </IonButton>
              <IonButton
                size="small"
                onClick={() => navigateTo("/salesPlans", { reload: true })}
              >
                <IonIcon slot="start" icon={add} />
                Sales & Marketing Activity
              </IonButton>
              <IonButton
                size="small"
                onClick={() => navigateTo("/faulty-plots", {})}
              >
                <IonIcon slot="start" icon={skullOutline} />
                Faulty Plots
              </IonButton>
              <IonButton
                size="small"
                onClick={() => navigateTo("/tabs/test-devices", {})}
              >
                <IonIcon slot="start" icon={constructOutline} />
                Testing Devices
              </IonButton>
              <IonButton
                size="small"
                onClick={() => navigateTo("/payment", {})}
              >
                <IonIcon slot="start" icon={qrCodeOutline} />
                Payment
              </IonButton>
              <IonButton
                size="small"
                onClick={() => navigateTo("/tabs/order", { orderId: null })}
              >
                <IonIcon slot="start" icon={add} />
                Order
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonList>
          <IonItem onClick={() => navigateTo("/tabs/leads", {})}>
            <IonBadge slot="end">{openLeads}</IonBadge>
            <IonLabel>Open leads</IonLabel>
          </IonItem>
          <IonItem onClick={() => navigateTo("/tabs/leads", {})}>
            <IonBadge slot="end">{unattendedLeads}</IonBadge>
            <IonLabel>Unattended leads</IonLabel>
          </IonItem>
          <IonItem onClick={() => navigateTo("/tabs/tickets", {})}>
            <IonBadge slot="end">{openTickets?.length ?? 0}</IonBadge>
            <IonLabel>Open Tickets</IonLabel>
          </IonItem>
          {faultyPlots > 0 && (
            <IonItem onClick={() => navigateTo("/faulty-plots", {})}>
              <IonBadge slot="end">{faultyPlots}</IonBadge>
              <IonLabel>Inactive Plots</IonLabel>
            </IonItem>
          )}
        </IonList>

        <IonGrid>
          <IonRow>
            <IonCol className="ion-align-self-center">
              <IonChip>
                <IonLabel>Upcoming Follow-ups</IonLabel>
              </IonChip>
            </IonCol>
          </IonRow>
        </IonGrid>
        <Leads leads={followups} />

        <IonGrid>
          <IonRow>
            <IonCol className="ion-align-self-center">
              <IonChip>
                <IonLabel>Upcoming Maintenances</IonLabel>
              </IonChip>
            </IonCol>
          </IonRow>
        </IonGrid>
        <Maintenances maintenances={tasks} />
      </IonContent>
    </IonPage>
  );
};

export default Home;
