import React from "react";
import { IonCard, IonIcon, IonItem, IonLabel } from "@ionic/react";
import useNavigation from "../hooks/useNavigation";
import { create, ellipse } from "ionicons/icons";
import "./LeadCard.css";
import { makeCall } from "../hooks/common";

interface IProps {
  ticket: any;
}

const TicketCard: React.FC<IProps> = (props) => {
  const navigateTo = useNavigation();

  return (
    <>
      <IonCard>

        <IonItem style={{ backgroundColor: "red" }}>
          {props.ticket.title !== undefined && (
            <>
              <IonIcon
                icon={ellipse}
                color={
                  props.ticket.priority_id === 1
                    ? "sucess"
                    : props.ticket.priority_id === 3
                      ? "danger"
                      : "warning"
                }
                style={{ marginRight: 5 }}
              ></IonIcon>
              <IonLabel>
                <h2>{`${props.ticket.title}`}</h2>
              </IonLabel>
            </>

          )}

          <IonIcon
            icon={create}
            onClick={(e) => navigateTo("/tabs/ticket", { ticketId: props.ticket.id, updateTicket: true })}
          ></IonIcon>
        </IonItem>
        <div
          onClick={() =>
            navigateTo("/tabs/ticket", { ticketId: props.ticket.id })
          }
        >

          {props.ticket.plotid &&
            <IonItem lines="none">
              <IonLabel>
                <h2>
                  {`Plot: `}<a onClick={e => { e.stopPropagation(); navigateTo('/tabs/plot', { farmId: props.ticket.plotid }) }}>{props.ticket.plotid}</a>
                </h2>
              </IonLabel>
            </IonItem>
          }
          {props.ticket.farmid &&
            <IonItem lines="none">
              <IonLabel>
                <h2>
                  {`Farm: `}<a onClick={e => { e.stopPropagation(); navigateTo('/tabs/farm', { farmId: props.ticket.farmid }) }}>{props.ticket.farmid}</a>
                </h2>
              </IonLabel>
            </IonItem>
          }
          {props.ticket.customername &&
            <IonItem lines="none">
              <IonLabel>
                <h2>
                  {`User: ${props.ticket.customername}`}
                </h2>
              </IonLabel>
            </IonItem>
          }
          {props.ticket.farmermobile &&
            <IonItem lines="none">
              <IonLabel>
                <h2>
                  {`Mobile: `}<a onClick={e => makeCall(e, props.ticket.farmermobile)}>{props.ticket.farmermobile}</a>
                </h2>
              </IonLabel>
            </IonItem>
          }
          {props.ticket.devicetype &&
            <IonItem lines="none">
              <IonLabel>
                <h2>
                  {`Device: ${props.ticket.devicetype}`}
                </h2>
              </IonLabel>
            </IonItem>
          }
          <IonItem lines="none">
            <IonLabel>
              <h2>
                {`Created On: ${new Intl.DateTimeFormat("en-GB", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                }).format(new Date(props.ticket.created_at))}`}
              </h2>
            </IonLabel>
          </IonItem>
        </div>
      </IonCard>
    </>
  );
};

export default TicketCard;
