import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonFab,
  IonFabButton,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/react";
import { add, chevronDownCircleOutline } from "ionicons/icons";
import Tickets from "../components/Tickets";
import { doReload } from "../hooks/common";
import useNavigation from "../hooks/useNavigation";
import { useRecoilValue } from "recoil";
import {
  openTicketsState,
  zammadProfileState,
} from "../services/state.service";
import { useEffect, useState } from "react";
import { getTickets } from "../services/zammad.service";
import { useLocation } from "react-router";
const TicketList: React.FC = () => {
  const navigateTo = useNavigation();
  const location = useLocation();
  const zammadProfile: any = useRecoilValue(zammadProfileState);
  const [tickets, setTickets] = useState<any>(useRecoilValue(openTicketsState));
  const updated: any = (location.state as any)?.updated ?? false;

  const fetchData = () => {
    setTickets([]);
    if (zammadProfile?.id) {
      getTickets(zammadProfile.id, 1).then((items) => {
        const ticketsWithOpenState = [];
        for (const ticketId of items?.tickets) {
          const ticketData = items?.assets?.Ticket[ticketId];
          if ([1, 2, 3].includes(ticketData.state_id)) {
            ticketsWithOpenState.push(ticketData);
          }
        }
        setTickets(ticketsWithOpenState);
      });
    }
  };

  const handleRefresh = (e: any) => {
    fetchData();
    e.detail.complete();
  };

  useEffect(() => {
    if (!!updated) {
      fetchData();
    }
  }, [updated]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Tickets</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText="Pull to refresh"
            refreshingSpinner="circles"
            refreshingText="Refreshing..."
          ></IonRefresherContent>
        </IonRefresher>
        <Tickets tickets={tickets} />
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton>
            <IonIcon
              icon={add}
              onClick={() => navigateTo("/tabs/ticket", { newTicket: true })}
            />
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};

export default TicketList;
