import React, { useEffect, useRef, useState } from "react";
import './CreateLeadOrder.css';
import { ActionSheetButton, IonActionSheet, IonButton, IonItem, IonLabel, IonText, IonTextarea, IonToast, IonToolbar } from "@ionic/react";
import { addOrder, updateOrder } from "../services/order.service";
import { userProfileState } from '../services/state.service';
import { useRecoilValue } from "recoil";
import { findPhoneNumbersInText } from 'libphonenumber-js';
import { useLocation } from "react-router";
import useNavigation from "../hooks/useNavigation";

// Define LeadStages as an enum
export enum LeadStages {
  NewLead = "newLead",
  FieldVisitScheduled = "fieldVisitScheduled",
  ReferenceCheck = "referenceCheck",
  Negotiation = "negotiation",
  Sold = "soldOut",
  NotNeeded = "notNeeded",
  Installing = "installing",
  Later = "later"
}

// Map LeadStages enum values to labels
export const LeadStagesLabels: Record<LeadStages, string> = {
  [LeadStages.NewLead]: "New Lead",
  [LeadStages.FieldVisitScheduled]: "Visit Scheduled",
  [LeadStages.ReferenceCheck]: "Reference check",
  [LeadStages.Negotiation]: "Negotiation",
  [LeadStages.Sold]: "Sold",
  [LeadStages.NotNeeded]: "Not needed",
  [LeadStages.Installing]: "Installing",
  [LeadStages.Later]: "Later"
};

const CreateLeadOrder: React.FC = () => {
  const [showLeadStageActionSheet, setShowLeadStageActionSheet] = useState<boolean>(false);

  const location = useLocation();
  const navigateTo = useNavigation();

  const leadStage = useRef<LeadStages>(LeadStages.NewLead);
  const [isNewLead, setIsNewLead] = useState<boolean>();
  const orderDetailRef = useRef<any>();
  const userProfile: any = useRecoilValue(userProfileState);
  const orderRef = useRef<any>();

  const [onSuccess, setOnSuccess] = useState("");
  const [onError, setOnError] = useState("");

  useEffect(() => {
    if (!!location?.state) {
      const { order } = location.state as any;
      if (order) {
        const { detail, isNewLead: newLead, stage } = order;

        setIsNewLead(newLead);
        leadStage.current = stage;

        orderRef.current = order;
        if (orderDetailRef.current) {
          (orderDetailRef.current as any).value = detail;
        }
      }
    }
  }, [location]);

  /*
    Event handlers
  */
  const openLeadStageActionSheet = () => {
    setShowLeadStageActionSheet(true);
  }

  const handleNewLead = (event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
    event.preventDefault();
    setIsNewLead(true);
  }

  const handleExistingLead = (event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
    event.preventDefault();
    setIsNewLead(false);
  }

  const closeLeadStageActionSheet = () => {
    setShowLeadStageActionSheet(false);
  }

  const handleStageChange = (newStageId: LeadStages) => {
    leadStage.current = newStageId;
  }

  /*
    API Calls
  */
  const saveOrder = async () => {
    const detail = (orderDetailRef.current as any).value ?? "";
    const user = userProfile.farmUserId;

    const orderMobiles = extractPhoneNumbers(detail);
    const stage = leadStage.current;
    if (!stage) {
      alert('Please select stage');
      return;
    }

    if (orderMobiles.length === 0) {
      alert('No mobile detected');
      return;
    }

    if (orderMobiles.length > 1) {
      alert('Multiple mobiles detected, selecting 1st number by default');
      return;
    }

    const payload: any = {
      detail,
      user,
      mobile: orderMobiles[0],
      stage,
      isNewLead: isNewLead
    }


    if (!!orderRef.current && !!orderRef.current.id) {
      payload.id = orderRef.current.id;
      updateOrder(payload).then(handleSaveResponse)
    } else {
      addOrder(payload).then(handleSaveResponse)
    }
  }

  const handleSaveResponse = (res: any) => {
    if (!!res.ok || !!res.id) {
      setOnSuccess("Saved successfully");
      setTimeout(() => {
        navigateTo('/orders', { refreshList: true });
      }, 500);
    } else {
      setOnSuccess("Failed to save");
    }
  }

  return (
    <div className="container">
      {/* text area */}
      <div>
        <IonTextarea
          rows={8}
          autofocus
          ref={orderDetailRef}
          className="text-area"
          placeholder="Enter Details"
          defaultValue={(orderDetailRef.current as any)?.value ?? ""}
        />
        <IonToolbar className="ion-toolbar">
          <IonButton
            fill={!!isNewLead ? "solid" : "outline"}
            onClick={handleNewLead}
            expand="block"
            className="stage-button"
            mode="ios"
          >
            New User
          </IonButton>
          <IonButton
            fill={!isNewLead ? "solid" : "outline"}
            onClick={handleExistingLead}
            expand="block"
            className="stage-button"
            mode="ios"
          >
            Existing User
          </IonButton>
        </IonToolbar>
        {!!leadStage.current && (
          <IonItem>
            <IonLabel>Stage</IonLabel>
            <IonText className="selected-stage" onClick={openLeadStageActionSheet} >
              {LeadStagesLabels[leadStage.current]}
            </IonText>
          </IonItem>
        )}
      </div>

      <IonButton
        fill="solid"
        color="success"
        onClick={saveOrder}
        expand="block"
        mode="ios"
      >
        Submit
      </IonButton>

      <LeadStagesActionSheet
        selected={leadStage.current}
        isOpen={showLeadStageActionSheet}
        onClose={closeLeadStageActionSheet}
        onChange={handleStageChange}
      />

      <IonToast
        isOpen={!!onSuccess}
        onDidDismiss={() => setOnSuccess("")}
        message={onSuccess}
        duration={2000}
        color="success"
      />

      <IonToast
        isOpen={!!onError}
        onDidDismiss={() => setOnError("")}
        message={onError}
        duration={2000}
        color="danger"
      />
    </div>
  )
}

export default CreateLeadOrder;

type ActionSheetProps = {
  isOpen: boolean;
  selected?: string;
  onClose: () => void;
  onChange: (id: LeadStages) => void;
}

export const LeadStagesActionSheet: React.FC<ActionSheetProps> = (props) => {
  const { isOpen, selected, onClose, onChange } = props;

  // Map LeadStages enum to ActionSheetButton
  const buttons = Object.entries(LeadStagesLabels).map(([key, value]) => {
    const stage = key as LeadStages;
    const disabled = [LeadStages.Installing, LeadStages.Later].includes(stage);
    return {
      text: value,
      role: key === selected ? 'selected' : 'default',
      handler() {
        if(!disabled) {
          onChange(stage);
        }
      },
      cssClass: disabled ? "disabled-button" : ''
    } as ActionSheetButton;
  });

  return (
    <IonActionSheet
      isOpen={isOpen}
      onDidDismiss={onClose}
      buttons={[
        ...buttons,
        {
          text: 'Cancel',
          role: 'cancel',
          handler() {
            onClose();
          },
        }
      ]}
    />
  )
}


const extractPhoneNumbers = (text: string): string[] => {
  const phoneNumbers = findPhoneNumbersInText(text, { defaultCountry: 'IN' }).map(response => {
    const { number } = response;
    return number.number;
  });

  return phoneNumbers;
};