import { clearAccessToken, deleteData, getData, postData } from "./common.service";

export async function getUsers(text: string = '', skip = 0) {
    const qs = {
        where: {
            or:
                [
                    { farmUserId: { like: `.*${text}.*`, options: 'i' } },
                    { name: { like: `.*${text}.*`, options: 'i' } },
                    { mobile: { like: `.*${text}.*`, options: 'i' } },
                    { countryCode: { like: `.*${text}.*`, options: 'i' } }
                ]
        },
        fields: ['farmUserId', 'name', 'mobile', 'countryCode'],
        order: 'created_date DESC',
        limit: 20,
        skip: skip
    };
    return getData('/farmusers?filter=' + JSON.stringify(qs), {
        sendTokenInUrl: true
    }).then(data => data.json())
}

export async function getUser(userId: string) {
    const qs = {
        include: 'roles'
    };
    return getData(`/farmusers/${userId}?filter=` + JSON.stringify(qs), {
        sendTokenInUrl: true
    }).then(data => data.json())
}

export async function getUserByMobile(mobile: string) {
    return getData(`/farmusers?filter=` + JSON.stringify({where: {mobile: mobile}}), {
        sendTokenInUrl: true
    }).then(data => data.json())
}

export async function searchUser(userId: string){
    const qs = {
        'fields': {
            'farmUserId': true
        }
    }
    return getData(`/farmusers/${userId}?filter=` + JSON.stringify(qs), { sendTokenInUrl: true })
            .then(data => data.json()).catch((e) => console.log(e));

}

export async function generateOTP(username: string) {
    return postData('/farmusers/generateOTP', {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: { username: username }
    });
}

export async function generateOTPNoSend(username: string) {
    return postData("/farmusers/generateOTPNoSend", {
        sendTokenInUrl: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: { username: username },
  });
}

export async function login(credentials: any) {
    return postData('/farmusers/dashboardLogin', {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: credentials
    });
}

export async function logoutUser() {
    return postData('/farmusers/logout', {
        sendTokenInUrl: true
    }).then(() => {
        clearAccessToken();
    });
}

export async function getUserProfile() {
    return getData('/farmusers/getfarmuserData', {
        sendTokenInUrl: true
    });
}

export async function canAccessCustomerData() {
    return getData('/farmusers/canAccessCustomerData', {
        sendTokenInUrl: true
    });
}

export async function registerUser(userData: any) {
    return postData('/farmusers/register', {
        sendTokenInUrl: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: userData
    });
}

export async function updateUser(userData: any) {
    return postData('/farmusers', {
        sendTokenInUrl: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: userData,
        usePutMethod: true
    });
}

export async function getRoles() {
    return [{
        'id': 'agronomist',
        'name': 'agronomist'
    }, {
        'id': 'farmManager',
        'name': 'farmManager'
    }, {
        'id': 'farmuser',
        'name': 'farmuser'
    }, {
        'id': 'ops',
        'name': 'ops'
    }, {
        'id': 'support',
        'name': 'support'
    }];
}

export async function getUserRoles(userId: string) {
    return getData(`/farmusers/${userId}/roles`, {
        sendTokenInUrl: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
}

export async function saveRole(userId: string, roleId: string) {
    return postData(`/farmusers/roles/${roleId}/${userId}`, {
        sendTokenInUrl: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        usePutMethod: true
    });
}

export async function deleteRole(userId: string, roleId: string) {
    return deleteData(`/farmusers/roles/${roleId}/${userId}`, {
        sendTokenInUrl: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
}