import {
    IonContent, IonHeader, IonPage,
    IonTitle, IonToolbar, IonButtons, IonMenuButton,
    IonFab, IonFabButton, IonIcon,
    IonRefresher, IonRefresherContent, IonInfiniteScroll, IonInfiniteScrollContent,
    IonButton
} from '@ionic/react';
import { add, chevronDownCircleOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { doReload } from '../hooks/common';
import useNavigation from '../hooks/useNavigation';
import { getUsers } from '../services/user.service';
// debounce hook
import useDebounce from "../hooks/useDebounce";
import CustomSearchBox from '../components/CustomSearchBox/CustomSearchBox';
import UserCard from '../components/UserCard';

interface IProps {
    selectedValue?: string;
    onClickHandler?: Function
    onClose?: Function
    hiddenItems?: any[];
}

const UserList: React.FC<IProps> = (props) => {
    const [searchText, setSearchText] = useState('');
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
    const [skip, setSkip] = useState<number>(20);
    const navigateTo = useNavigation();
    const debounceSearchText = useDebounce(searchText, 1000);

    const [users, setUsers] = useState([] as any[]);
    useEffect(() => {
        let mounted = true;
        setSkip(20);
        setDisableInfiniteScroll(false);
        getUsers(debounceSearchText)
            .then(items => {
                if (mounted) {
                    if (props.hiddenItems) {
                        items = items.filter((i:any) => {
                            return !props.hiddenItems?.includes(i.farmUserId);
                        })
                    }
                    setUsers(items);
                }
            });
        return () => { mounted = false };
    }, [debounceSearchText]);

    async function fetchNext($event: CustomEvent<void>) {
        setSkip(skip + 20);
        getUsers(debounceSearchText, skip)
            .then(items => {
                if (items?.length > 0) {
                    if (props.hiddenItems) {
                        items = items.filter((i:any) => {
                            return !props.hiddenItems?.includes(i.farmUserId);
                        })
                    }
                    setUsers([...users, ...items]);
                    setDisableInfiniteScroll(items.length < 20);
                } else {
                    setDisableInfiniteScroll(true);
                }
                ($event.target as HTMLIonInfiniteScrollElement).complete();
            });
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    {
                        props.onClose
                            ?
                            <IonButtons slot="end">
                                <IonButton onClick={e => props.onClose && props.onClose()}>
                                    Close
                                </IonButton>
                            </IonButtons>
                            :
                            <IonButtons slot="start">
                                <IonMenuButton />
                            </IonButtons>
                    }
                    <IonTitle>Users</IonTitle>
                </IonToolbar>
                <CustomSearchBox setSearchText={setSearchText} />
            </IonHeader>
            <IonContent>
                <IonRefresher slot="fixed" onIonRefresh={doReload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>
                {users && users.map((user, index) => <UserCard user={user} key={index} selectedValue={props?.selectedValue} onClickHandler={props?.onClickHandler} />)}
                <IonInfiniteScroll threshold="100px"
                    disabled={disableInfiniteScroll}
                    onIonInfinite={(e: CustomEvent<void>) => fetchNext(e)}>
                    <IonInfiniteScrollContent
                        loadingText="Loading more...">
                    </IonInfiniteScrollContent>
                </IonInfiniteScroll>
                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton>
                        <IonIcon icon={add} onClick={() => navigateTo('/tabs/user', { userId: null })} />
                    </IonFabButton>
                </IonFab>
            </IonContent>
        </IonPage>
    );
};

export default UserList;
